.experience {
    text-align: center;
    font-family: "Advent Pro", sans-serif;
    border-top: 2px solid rgb(255, 255, 255);
    border-bottom: 2px solid rgb(255, 255, 255);
    width: 70vw;
    margin: 3vh auto;
    background-color: rgba(94, 31, 31, 0.523);
    color: white;
}

.experience h1 {
    font-family: "Advent Pro", sans-serif;
    margin: 1.5vh;
}

.carousel {
    height: 65vh;
    width: 50vw;
    margin: 5vh auto;
    text-align: center;
    background-color: black;
    color: aliceblue;
    overflow: hidden;
}

.container p{
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .experience {
        width: 85vw;
        margin: 2vh auto;
        border-top: 3px solid rgb(255, 255, 255);
        border-bottom: 3px solid rgb(255, 255, 255);
    }

    .experience h1 {
        font-size: 2rem;
        margin: 2vh 0;
    }

    .More {
        height: 12vh;
        width: 90vw;
        margin: 4vh 5vw;
    }

    .carousel {
        height: auto;
        width: 80vw;
        margin: 4vh auto;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .experience {
        width: 80vw;
        margin: 2vh auto;
        border-top: 2px solid rgb(255, 255, 255);
        border-bottom: 2px solid rgb(255, 255, 255);
    }

    .experience h1 {
        font-size: 2.5rem;
        margin: 2vh 0;
    }

    .More {
        height: 10vh;
        width: 85vw;
        margin: 3vh 7.5vw;
    }

    .carousel {
        height: auto;
        width: 75vw;
        margin: 3vh auto;
    }
}

@media only screen and (max-width: 599px) {
    .experience {
        width: 90vw;
        margin: 2vh auto;
    }

    .experience h1 {
        font-size: 1.5rem;
        margin: 1vh 0;
    }

    .More {
        height: 15vh;
        width: 90vw;
        margin: 3vh 5vw;
    }

    .carousel {
        height: auto;
        width: 90vw;
        margin: 3vh auto;
    }
}
