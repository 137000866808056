
.outernavbar {
    height: 8vh;
    width: 70vw;
    margin: auto;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    display: flex;
    align-items: center;
    background: #f5deb385;
}

.navbar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 70vw;
}

.navbar li {
    list-style: none;
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 600;
    text-transform: uppercase;
}

.navbar li:hover {
    color: rgba(0, 0, 0, 0.555);
    scale: 1.1;
}

.outernavbar {
    height: 8vh;
    width: 70vw;
    margin: auto;
    display: flex;
    align-items: center;
    background: #f5deb385;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}

.menu-btn {
    font-size: 50px;
    background-color: transparent;
    color: rgb(60, 51, 51);
    border: none;
    cursor: pointer;
    display: none; 
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

@media screen and (max-width: 1024px) {
    .outernavbar {
        position: absolute;
        top: 0;
        height: auto;
        width: auto;
        margin: auto;
        display: flex;
        align-items: center;
        background: none;
        border: 0;
    }    
    .navbar{
        display: none;
    }

    .menu-btn {
        font-size: 50px;
        background-color: transparent;
        color: rgb(60, 51, 51);
        border: none;
        cursor: pointer;
        display: block; 
    }

    .sidenav {
        width: 0;
    }
}
