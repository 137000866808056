.banner {
    height: 40vh;
    width: 70vw;
    margin: 10vh 5vw;
    display: grid;
    grid-template-columns: auto auto;
}

.right {
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    padding: 1vh;
}

.right h3 {
    font-size: 1.5rem;
}

.Imgslide {
    height: 70vh;
    width: 70vw;
    margin: 15vh auto;
    display: grid;
    grid-template-columns: auto auto auto;
}

.Interactive {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Advent Pro", sans-serif;
    border-top: 2px solid rgb(255, 255, 255);
    border-bottom: 2px solid rgb(255, 255, 255);
    width: 70vw;
    margin: auto;
    background-color: rgba(94, 31, 31, 0.523);
    color: white;
}

.Interactive h1 {
    margin: auto;
}

.pic {
    height: 30vh;
    width: 20vw;
    transition: all 0.4s ease-in-out;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.pic:hover {
    margin: 10px;
    z-index: 1;
    transform: scale(1.5);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid black;
    backdrop-filter: blur(5px);
}


.homesubjects {
    display: grid;
    grid-template-rows: auto auto auto auto;
    margin: 5vh auto;
    height: 50vh;
    width: 70vw;
}

.physics, .chemistry, .maths, .bio, .science {
    height: 7vh;
    width: 70vw;
    margin: auto;
    background: #ffffffa0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease;
}

.physics:hover, .chemistry:hover, .maths:hover, .bio:hover, .science:hover {
    background: #2f2e2e9f;
    cursor: pointer;
    color: white;
}

.physics h2, .chemistry h2, .maths h2, .bio h2, .science h2 {
    font-size: 27px;
    margin-left: 2vw;
    margin-top: 8px;
    font-family: "Advent Pro", sans-serif;
    font-weight: 700;
}

span {
    margin: 0 2vw;
    font-size: 23px;
}

.boards b {
    font-family: "Advent Pro", sans-serif;
    font-weight: 700;
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .banner {
        height: 40vh;
        width: 70vw;
        margin: 0vh 0vw;
        display: grid;
        grid-template-columns: auto auto;
    }
    
    .banner, .Imgslide, .Interactive, .homesubjects {
        width: 85vw;
    }

    .banner {
        margin: 5vh 7.5vw;
        height: 35vh;
    }

    .Imgslide {
        height: 60vh;
    }

    .pic {
        height: 25vh;
        width: 25vw;
    }

    .homesubjects {
        height: 60vh;
    }

    .physics, .chemistry, .maths, .bio, .science {
        height: 10vh;
    }

    .physics h2, .chemistry h2, .maths h2, .bio h2, .science h2 {
        font-size: 24px;
    }

    span {
        font-size: 20px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .banner, .Imgslide, .Interactive, .homesubjects {
        width: 80vw;
    }

    .banner {
        height: 40vh;
        width: 70vw;
        margin: 0vh 0vw;
        display: grid;
        grid-template-columns: auto auto;
    }

    .Imgslide {
        height: 65vh;
    }

    .pic {
        height: 28vh;
        width: 22vw;
    }

    .homesubjects {
        height: 55vh;
    }

    .physics, .chemistry, .maths, .bio, .science {
        height: 8vh;
    }

    .physics h2, .chemistry h2, .maths h2, .bio h2, .science h2 {
        font-size: 26px;
    }

    span {
        font-size: 22px;
    }
}

@media only screen and (max-width: 599px) {
    .banner, .Imgslide, .Interactive, .homesubjects {
        width: 95vw;
    }

    .banner {
        height: 40vh;
        width: 93vw;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 25vh;
        display: grid;
        grid-template-columns: auto auto;
    }
        
        
    .right h3 {
        font-size: 1.2rem;
    }

    .Imgslide {
        grid-template-columns: 1fr;
        height: auto;
    }

    .pic {
        height: 20vh;
        width: 90vw;
        margin: 2vh 0;
    }

    .homesubjects {
        grid-template-rows: auto;
        height: auto;
    }

    .physics, .chemistry, .maths, .bio, .science {
        height: 17vh;
        width: 94vw;
        margin: auto;
        background: #ffffffa0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease;
    }
    .pic{
        margin: 2vh auto;
    }
    .physics h2, .chemistry h2, .maths h2, .bio h2, .science h2 {
        font-size: 17px;
        margin-top: 0;
    }

    
    .Imgslide {
        grid-template-columns: 1fr;
        height: auto;
        margin: 5vh auto;
    }

    .boards{
        font-size: 16px;
    }
    span {
        font-size: 18px;
    }
}
