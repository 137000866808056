.RegistrationForm {
    height: 88vh;
    width: 62vw;
    margin: 15vh auto;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.RegistrationForm::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.62;
    z-index: -1;
    pointer-events: none;
}

.RegistrationForm>* {
    position: relative;
    z-index: 2;
}

.RegistrationForm h2 {
    font-family: 'Sacramento';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 93px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    margin: 4vh auto;
}

.RegistrationForm {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 42px;
    display: flex;
    color: #000000;
    align-items: center;
    justify-content: center;
}

.inputs {
    height: 4vh;
    width: 20vw;
    margin: 0 2vw;
}

.class{
    margin: 0 1vw;
}

.backto{
    width: 7vw;
  height: 5vh;
  margin: 0 auto;
}

.schoolname span{
    font-size: 15px;
    color: red;
}

@media (min-width: 600px) and (max-width: 1024px) {
    .RegistrationForm {
        width: 80vw; 
        padding: 3vh 4vw; 
    }

    .RegistrationForm h2 {
        font-size: 6vw; 
    }

    .RegistrationForm p {
        font-size: 4vw; 
    }

    .inputs {
        width: 60vw; 
    }

    .RegistrationForm::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: none;
        opacity: 0.62;
        z-index: -1;
        pointer-events: none;
    }

    .backto {
        width: 30vw;
        height: 5vh;
    }

    .schoolname span {
        font-size: 2.5vw; 
    }
}

@media (max-width: 599px) {
    .RegistrationForm {
        width: 95vw; 
        padding: 2vh 3vw;
    }
    .RegistrationForm::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: none;
        opacity: 0.62;
        z-index: -1;
        pointer-events: none;
    }

    .RegistrationForm h2 {
        font-size: 7vw; 
    }

    .RegistrationForm p {
        font-size: 4.5vw; 
    }

    .inputs {
        width: 85vw;
    }

    .backto {
        width: 60vw;
        height: 5.5vh;
    }

    .schoolname span {
        font-size: 3.5vw; 
    }
}
