
.logo {
    background-image: url("../Images/logo-no-background.png");
    height: 20vh;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20vw;
    background-position: center;
    margin-top: 3vh;
}

.head {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    height: 20vh;
    margin-bottom: 5vh;
}

.sun {
    height: 15vh;
    width: 7vw;
    background-image: url("../Images/sun.png");
    background-size: contain;
    background-repeat: no-repeat;
    transition: transform 0.5s ease;
    cursor: pointer;
    margin-top: 3vh;
}

.moon {
    height: 10vh;
    width: 5vw;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../Images/full-moon.png");
    transition: transform 0.5s ease;
    cursor: pointer;
    margin-top: 6vh;
}

.moon:hover, .sun:hover {
    transform: rotateZ(360deg);
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .logo {
        height: 18vh;
        width: 25vw;
    }

    .sun {
        height: 13vh;
        width: 8vw;
        visibility: hidden;
    }

    .moon {
        height: 8vh;
        width: 6vw;
        margin-top: 5vh;
        visibility: hidden;
    }

    .head {
        height: 18vh;
        margin-bottom: 4vh;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .logo {
        height: 19vh;
        width: 22vw;
    }

    .sun {
        height: 14vh;
        width: 7.5vw;
        visibility: hidden;
    }

    .moon {
        height: 9vh;
        width: 5.5vw;
        margin-top: 5.5vh;
        visibility: hidden;
    }

    .head {
        height: 19vh;
        margin-bottom: 4.5vh;
    }
}

@media only screen and (max-width: 599px) {
    .logo {
        height: 15vh;
        width: 127vw;
        margin-top: 2vh;
    }

    .sun {
        height: 10vh;
        width: 15vw;
        margin-top: 1vh;
        visibility: hidden;
    }

    .moon {
        height: 7vh;
        width: 10vw;
        margin-top: 4vh;
        visibility: hidden;
    }

    .head {
        flex-direction: column;
        align-items: center;
        height: auto;
        margin-bottom: 3vh;
    }
}
